/* PrismJS 1.15.0
https://prismjs.com/download.html#themes=prism-okaidia&languages=markup+css+clike+javascript+abap+actionscript+ada+apacheconf+apl+applescript+c+arff+asciidoc+asm6502+csharp+autohotkey+autoit+bash+basic+batch+bison+brainfuck+bro+cpp+aspnet+arduino+cil+coffeescript+clojure+ruby+csp+css-extras+d+dart+diff+django+docker+eiffel+elixir+elm+markup-templating+erlang+fsharp+flow+fortran+gcode+gedcom+gherkin+git+glsl+gml+go+graphql+groovy+less+handlebars+haskell+haxe+http+hpkp+hsts+ichigojam+icon+inform7+ini+io+j+java+javastacktrace+jolie+json+julia+keyman+kotlin+latex+markdown+liquid+lisp+livescript+lolcode+lua+makefile+crystal+erb+matlab+mel+mizar+monkey+n4js+nasm+nginx+nim+nix+nsis+objectivec+ocaml+opencl+oz+parigp+parser+pascal+perl+php+php-extras+sql+powershell+processing+prolog+properties+protobuf+scss+puppet+pure+python+q+qore+r+jsx+typescript+renpy+reason+rest+rip+roboconf+textile+rust+sas+sass+stylus+scala+scheme+smalltalk+smarty+plsql+soy+pug+swift+yaml+tcl+haml+toml+tt2+twig+tsx+vbnet+velocity+verilog+vhdl+vim+visual-basic+wasm+wiki+xeora+xojo+xquery+tap&plugins=line-numbers */
/**
 * okaidia theme for JavaScript, CSS and HTML
 * Loosely based on Monokai textmate theme by http://www.monokai.nl/
 * @author ocodia
 */
code[class*="language-"],
pre[class*="language-"] {
  color: #f8f8f2;
  background: none;
  text-shadow: 0 1px rgba(0, 0, 0, 0.3);
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none; }

/* Code blocks */
pre[class*="language-"] {
  padding: 1em;
  margin: .5em 0;
  overflow: auto;
  border-radius: 0.3em; }

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: #272822; }

/* Inline code */
:not(pre) > code[class*="language-"] {
  padding: .1em;
  border-radius: .3em;
  white-space: normal; }

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: slategray; }

.token.punctuation {
  color: #f8f8f2; }

.namespace {
  opacity: .7; }

.token.property,
.token.tag,
.token.constant,
.token.symbol,
.token.deleted {
  color: #f92672; }

.token.boolean,
.token.number {
  color: #ae81ff; }

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #a6e22e; }

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string,
.token.variable {
  color: #f8f8f2; }

.token.atrule,
.token.attr-value,
.token.function,
.token.class-name {
  color: #e6db74; }

.token.keyword {
  color: #66d9ef; }

.token.regex,
.token.important {
  color: #fd971f; }

.token.important,
.token.bold {
  font-weight: bold; }

.token.italic {
  font-style: italic; }

.token.entity {
  cursor: help; }

pre[class*="language-"].line-numbers {
  position: relative;
  padding-left: 3.8em;
  counter-reset: linenumber; }

pre[class*="language-"].line-numbers > code {
  position: relative;
  white-space: inherit; }

.line-numbers .line-numbers-rows {
  position: absolute;
  pointer-events: none;
  top: 0;
  font-size: 100%;
  left: -3.8em;
  width: 3em;
  /* works for line-numbers below 1000 lines */
  letter-spacing: -1px;
  border-right: 1px solid #999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.line-numbers-rows > span {
  pointer-events: none;
  display: block;
  counter-increment: linenumber; }

.line-numbers-rows > span:before {
  content: counter(linenumber);
  color: #999;
  display: block;
  padding-right: 0.8em;
  text-align: right; }

figure.product-logo-img {
  position: relative;
  width: 100%;
  height: 70%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  figure.product-logo-img .dropzone {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }
  figure.product-logo-img img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }

.owl-carousel .owl-stage {
  display: flex; }

.owl-carousel .owl-item img {
  width: 100%;
  height: 100%; }

.owl-carousel .item-video {
  background-color: black;
  height: 100%; }

.owl-carousel .owl-video-tn {
  position: relative;
  background-size: cover;
  overflow: hidden;
  height: 100%; }

.owl-carousel .maxh {
  height: 100%;
  max-height: 100vh;
  background-color: red; }

i.fa-star {
  color: #F9D02C;
  font-size: 22px; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 2px solid rgba(0, 0, 0, 0.1); }

.category-list li {
  line-height: 1.9;
  font-size: 80%;
  font-weight: 400;
  color: #6c757d; }

.editable:hover {
  border: 1px solid blue; }

.badge-list:focus {
  outline: none; }

h2.desc-title {
  line-height: 1.5; }

.text-bold-secondary {
  color: #3F80D8;
  font-weight: bold;
  font-size: 18px; }

.text-bold-primary {
  color: #CECECF;
  font-weight: bold;
  font-size: 18px; }

:not(pre) > code[class*="language-"], pre[class*="language-"] {
  background-color: transparent; }

section.main {
  padding-top: 68px;
  padding-bottom: 100px; }

.page-status {
  margin-bottom: 24px; }
  .page-status .fa-check {
    font-size: 16px;
    color: #96D35D; }
  .page-status .status-text {
    font-size: 16px;
    color: #96D35D;
    margin-left: 4px; }

header.page-header {
  margin-bottom: 32px; }
  header.page-header .header-title {
    width: 100%; }
    header.page-header .header-title .title .ql-container .ql-editor p {
      font-weight: bold;
      font-size: 42px;
      line-height: 1.5;
      color: white; }

.header-action-btn {
  background-image: linear-gradient(to right, #1E5EFF 0%, #A4E866 51%, #1E5EFF 100%);
  background-size: 200% auto;
  transition: 0.5s;
  height: 48px;
  border-radius: 24px;
  font-size: 15px;
  padding: 0 52px;
  border: none;
  cursor: pointer;
  color: white;
  font-weight: 600;
  min-width: 180px;
  margin-bottom: 10px;
  margin-right: 20px; }
  .header-action-btn:hover, .header-action-btn:focus, .header-action-btn:active {
    background-position: right center;
    outline: none;
    box-shadow: none; }

.header-action {
  display: flex; }
  .header-action .header-action-btn {
    background-image: linear-gradient(to right, #1E5EFF 0%, #A4E866 51%, #1E5EFF 100%);
    background-size: 200% auto;
    transition: 0.5s;
    height: 48px;
    border-radius: 24px;
    font-size: 15px;
    padding: 0 52px;
    border: none;
    cursor: pointer;
    color: white;
    font-weight: 600;
    min-width: 180px;
    margin-bottom: 10px;
    margin-right: 20px; }
    .header-action .header-action-btn:hover, .header-action .header-action-btn:focus, .header-action .header-action-btn:active {
      background-position: right center;
      outline: none;
      box-shadow: none; }
  .header-action .publish-button {
    background-image: none !important;
    background-color: #77B340; }

.btn-grad {
  background-image: linear-gradient(to right, #2b5876 0%, #4e4376 51%, #2b5876 100%); }

.btn-grad:hover {
  background-position: right center; }

.owner-info {
  margin-bottom: 24px; }
  .owner-info .avatar-name p {
    margin-bottom: 4px;
    font-size: 14px; }
  .owner-info .avatar-name .owner-name {
    font-size: 18px;
    font-weight: 700; }
  .owner-info .avatar-wrap {
    background-color: transparent;
    position: relative;
    overflow: hidden; }
    .owner-info .avatar-wrap img {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%; }
  .owner-info .github-link a {
    color: #717172; }

.github-data {
  border: 1px solid #1B1B1B;
  border-radius: 12px;
  margin-bottom: 25px; }
  .github-data .row div[class*="col-"] {
    border-radius: 12px;
    display: flex;
    background-color: #161616; }
    .github-data .row div[class*="col-"] + div[class*="col-"] .data-content {
      border-left: 1px solid #1B1B1B; }
  .github-data .data-content {
    padding: 0 40px;
    min-height: 84px;
    width: 100%;
    position: relative; }
    .github-data .data-content h4.data-type {
      margin-top: 20px;
      margin-bottom: 4px;
      font-size: 14px;
      font-weight: normal;
      color: #BABABA; }
    .github-data .data-content .data-value .fa-star {
      color: #F5A623; }
    .github-data .data-content .info-icon {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 16px;
      height: 16px; }

.swift-icon {
  width: 18px; }

.rounded-circle {
  width: 28px;
  height: 28px;
  background-color: grey; }

p.project-desc {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 400;
  color: #E3E3E3;
  line-height: 1.5; }

.code {
  width: 100%;
  height: 100%;
  max-height: 320px; }
  .code textarea {
    margin-top: 0;
    color: white;
    resize: none;
    background-color: #161616; }
  .code pre {
    width: 100%;
    height: 100%;
    padding: 0;
    margin-top: 0; }

.sec-content-box {
  border: 1px solid #1B1B1B;
  border-radius: 8px;
  background-color: #181818;
  padding: 24px 30px; }

.main-content {
  border: 1px solid #1B1B1B;
  border-radius: 12px;
  background-color: #161616; }
  .main-content header.overview-header {
    border-bottom: 1px solid #1B1B1B;
    border-radius: 12px 12px 0 0;
    background-color: #161616;
    max-height: 73px; }
    .main-content header.overview-header .row div[class*='col-'] {
      display: flex; }
    .main-content header.overview-header .data-content {
      padding: 0 40px;
      min-height: 70px;
      width: 100%;
      border-radius: 8px; }
      .main-content header.overview-header .data-content .apple-logo-sm {
        width: 30px;
        height: auto; }
      .main-content header.overview-header .data-content h4.data-type {
        font-size: 14px;
        font-weight: normal;
        color: #BABABA;
        margin-top: 14px;
        margin-bottom: 4px; }
  .main-content .overview-main-content {
    padding: 40px; }
    .main-content .overview-main-content section.sec-sep {
      margin-bottom: 48px; }
      .main-content .overview-main-content section.sec-sep ul.product-hashtag-list li {
        font-size: 14px;
        font-weight: 600;
        height: 35px;
        line-height: 32px;
        color: #FFFFFF;
        padding: 0px 11px;
        border-radius: 17px;
        border: 1px solid #1B1B1B;
        background-color: #0C0C0C; }
        .main-content .overview-main-content section.sec-sep ul.product-hashtag-list li + li {
          margin-left: 8px; }
      .main-content .overview-main-content section.sec-sep h2.sec-title {
        font-size: 24px;
        font-weight: 600;
        margin: 0;
        margin-bottom: 26px; }
      .main-content .overview-main-content section.sec-sep p.sec-desc {
        font-size: 16px;
        color: #E3E3E3;
        margin-bottom: 40px; }
      .main-content .overview-main-content section.sec-sep .code-example-wrap {
        margin-bottom: 40px;
        height: 374px;
        background-color: #181818;
        border: 1px solid #212121;
        border-radius: 4px;
        padding: 30px 30px; }
      .main-content .overview-main-content section.sec-sep.screenshots .screenshot-wrap {
        overflow-x: auto;
        margin-top: 20px; }
        .main-content .overview-main-content section.sec-sep.screenshots .screenshot-wrap .image-name {
          margin-top: 10px;
          margin-right: 15%;
          padding-bottom: 1px;
          padding-left: 1px; }
        .main-content .overview-main-content section.sec-sep.screenshots .screenshot-wrap .upload-button {
          cursor: default; }
          .main-content .overview-main-content section.sec-sep.screenshots .screenshot-wrap .upload-button:hover {
            opacity: 0.5; }
            .main-content .overview-main-content section.sec-sep.screenshots .screenshot-wrap .upload-button:hover .upload-icon {
              position: absolute;
              left: 45%;
              top: 50%;
              display: block;
              cursor: pointer;
              color: white;
              font-size: 30px; }
            .main-content .overview-main-content section.sec-sep.screenshots .screenshot-wrap .upload-button:hover .remove-icon {
              position: absolute;
              right: 35%;
              top: 50%;
              display: block;
              cursor: pointer;
              color: red;
              font-size: 30px; }
          .main-content .overview-main-content section.sec-sep.screenshots .screenshot-wrap .upload-button .upload-icon, .main-content .overview-main-content section.sec-sep.screenshots .screenshot-wrap .upload-button .remove-icon {
            display: none; }
        .main-content .overview-main-content section.sec-sep.screenshots .screenshot-wrap .screenshot-fig {
          text-align: center;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.8);
          cursor: default;
          height: 497px;
          width: 230px;
          object-fit: contain; }
          .main-content .overview-main-content section.sec-sep.screenshots .screenshot-wrap .screenshot-fig img {
            width: 100%; }
          .main-content .overview-main-content section.sec-sep.screenshots .screenshot-wrap .screenshot-fig figcaption {
            margin: 6px 0; }
      .main-content .overview-main-content section.sec-sep.installation {
        display: block; }
        .main-content .overview-main-content section.sec-sep.installation .nav-pills {
          margin-bottom: 10px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          max-width: 40%;
          flex-wrap: nowrap; }
          .main-content .overview-main-content section.sec-sep.installation .nav-pills li + li {
            margin-left: 8px; }
          .main-content .overview-main-content section.sec-sep.installation .nav-pills li a {
            border-radius: 4px;
            height: 46px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 40px;
            transition: all 0.3s ease;
            font-size: 16px;
            color: #717172; }
            .main-content .overview-main-content section.sec-sep.installation .nav-pills li a:hover, .main-content .overview-main-content section.sec-sep.installation .nav-pills li a:focus {
              text-decoration: none;
              outline: none;
              background-color: #6c757d;
              color: white; }
            .main-content .overview-main-content section.sec-sep.installation .nav-pills li a.active {
              background-color: #03C89B;
              border-radius: 4px;
              height: 46px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0 40px;
              font-size: 16px;
              color: white;
              font-weight: 600; }
        .main-content .overview-main-content section.sec-sep.installation .tab-content {
          height: 235px;
          background-color: #181818;
          border: 1px solid #1B1B1B;
          border-radius: 4px;
          padding: 24px; }
          .main-content .overview-main-content section.sec-sep.installation .tab-content h3 {
            font-size: 16px;
            font-family: "Open sans", sans-serif; }
          .main-content .overview-main-content section.sec-sep.installation .tab-content p {
            font-size: 16px;
            color: #E3E3E3; }

.ant-message-custom-content .anticon {
  vertical-align: 0;
  top: 0; }

.ant-upload.ant-upload-select-picture-card {
  width: 200px;
  display: inline-table; }

.ant-upload-list-picture-card {
  display: none; }

.image-container {
  border: 7px solid white;
  padding: 0;
  margin-right: 14px;
  border-radius: 5px;
  margin-bottom: 19px; }
  .image-container * {
    padding: 0;
    margin: 0; }
  .image-container .upload-icon {
    display: none; }
  .image-container .remove-icon {
    display: none; }
  .image-container:hover {
    opacity: 0.5; }
    .image-container:hover .upload-icon {
      position: absolute;
      left: 45%;
      top: 50%;
      display: block;
      cursor: pointer;
      color: white;
      font-size: 30px; }
    .image-container:hover .remove-icon {
      position: absolute;
      right: 45%;
      top: 50%;
      display: block;
      cursor: pointer;
      color: red;
      font-size: 30px; }
  .image-container .ant-upload-list-picture-card {
    display: block; }
  .image-container .image-actions {
    top: 50%;
    left: 40%;
    cursor: pointer;
    position: absolute; }
    .image-container .image-actions :hover {
      background: none; }
      .image-container .image-actions :hover .ant-upload-list-item-done {
        background: none; }
      .image-container .image-actions :hover .ant-upload-list {
        background: none; }
      .image-container .image-actions :hover .ant-upload-list-picture-card {
        background: none; }
  .image-container .ant-upload-list-item-done {
    height: 497px;
    width: 230px; }
    .image-container .ant-upload-list-item-done :hover {
      background: none; }

@media (min-width: 576px) {
  header.page-header .header-title .title {
    margin-right: 10px;
    font-size: 42px; } }

.ql-tooltip {
  display: none; }

.ql-editor.ql-blank::before {
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  font-family: "Open sans", sans-serif; }

.ql-editor {
  padding: 0; }
  .ql-editor p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px;
    font-family: "Open sans", sans-serif; }
  .ql-editor :hover {
    background-color: #282828; }

.code-output-img .ant-upload {
  padding: 4px 0 !important;
  width: 160px; }
  .code-output-img .ant-upload :hover {
    opacity: 0.8; }
    .code-output-img .ant-upload :hover .remove-icon {
      display: block; }
  .code-output-img .ant-upload .remove-icon {
    font-size: 20px;
    display: none;
    position: absolute;
    color: red;
    top: 45%;
    left: 40%; }

.container {
  overflow-y: scroll;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */ }

.container::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0; }

.container-styles {
  width: 80%;
  padding-top: 20px;
  padding-bottom: 20px; }

.header, .content {
  width: 100%; }

.header {
  text-align: center;
  color: white; }
  .header img {
    margin-right: 10px; }

.header-logo {
  width: 10%;
  border: 1px solid #101010;
  float: left; }
  .header-logo img {
    width: 100%; }

.header-title {
  font-weight: 600; }

.progress-steps {
  width: 100%;
  margin-left: 25%; }
  .progress-steps ul {
    border-top: 4px solid #ccc;
    height: 1px;
    width: 50%; }
    .progress-steps ul li {
      height: 14px; }
      .progress-steps ul li .rounded-circle {
        width: 28px;
        height: 27px;
        background-color: #fff;
        border: 12px solid #ccc;
        line-height: 1; }

.content-title {
  margin-top: 20px;
  font-size: 16px;
  color: #CECECF; }

.form-control__input {
  width: 50%;
  margin: 0px auto; }

.form-control__button {
  background-color: #1e5eff;
  height: 48px;
  font-size: 15px;
  padding: 0 52px;
  border: none;
  width: 35%;
  cursor: pointer;
  color: white;
  font-weight: 600;
  margin-bottom: 10px; }
  .form-control__button:hover, .form-control__button:focus, .form-control__button:active {
    background-color: #1e5eff;
    color: white;
    background-position: right center;
    outline: none;
    box-shadow: none; }

.footer {
  text-align: center;
  position: fixed;
  bottom: 0;
  margin-bottom: 40px; }
  .footer a {
    margin-left: 20px;
    color: #ffffff; }

.repo-input {
  height: 100%;
  width: 100%;
  font-family: "Open sans", sans-serif; }
  .repo-input .ant-form-explain {
    width: 50%;
    margin: 0 auto; }
  .repo-input .header-title {
    color: #ffffff;
    position: absolute;
    left: 36%; }

.header-title h1 {
  font-weight: bold;
  font-size: 42px;
  line-height: 1.5;
  color: white;
  margin: 0; }

.header-title p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  font-family: "Open sans", sans-serif; }

#root {
  min-width: 1200px;
  height: 100%;
  width: 100%; }

body {
  background-color: #151515;
  color: #FFFFFF;
  font-family: "Open sans", sans-serif; }

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: white; }
